'use client';
import { BackButton } from './back-button.component';
import { redirectToAction } from '../actions/redirect-to.action';
import { useFormState } from 'react-dom';

export const RedirectBack = ({
  redirectTo,
  textButton = 'Back',
}: {
  redirectTo: string;
  textButton?: string;
}) => {
  const [, dispatch] = useFormState(redirectToAction, undefined);

  return (
    <form action={dispatch}>
      <input type="hidden" name="redirect-back" value={redirectTo} />
      <BackButton>{textButton}</BackButton>
    </form>
  );
};
