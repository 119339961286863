'use client';
import { CountryCode, getCountryFlag } from '@prickle/next-ui';
import { CountryRegionData } from 'react-country-region-selector';

export const ShowCountryName = ({ country }: { country: string }) => {
  const countryRegion = CountryRegionData.find((c) => c[1] === country);
  const countryName = countryRegion?.[0] || 'N/A';

  return (
    <span>
      {getCountryFlag(country.toLowerCase() as CountryCode)} {countryName}
    </span>
  );
};
