import { IServerActionState } from '@prickle/dto';
import { Alert } from '@prickle/next-ui';
import { TbAlertCircle, TbCircleCheck } from 'react-icons/tb';

export const ServerMessage = ({
  isOk,
  issues,
  message,
}: IServerActionState) => {
  if (issues.length === 0 && message === '') {
    return null;
  }

  if (isOk && message !== '') {
    return (
      <Alert color="success">
        <TbCircleCheck size={32} /> {message}
      </Alert>
    );
  }

  return (
    <Alert color="error">
      <TbAlertCircle size={32} />
      <div className="grid grid-cols-1">
        <p>{message}</p>
        {issues.map((issue, index) => (
          <p className="block" key={index}>
            {issue as string}
          </p>
        ))}
      </div>
    </Alert>
  );
};
