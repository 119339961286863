'use client';
import { Button, Loading } from '@prickle/next-ui';
import { cn } from '@prickle/ui';
import { useFormStatus } from 'react-dom';
import { IoArrowBack } from 'react-icons/io5';

export const BackButton = ({
  children,
  className,
  position = 'relative',
}: {
  children: React.ReactNode;
  className?: string;
  position?: 'absolute' | 'relative' | 'fixed';
}) => {
  const { pending } = useFormStatus();
  const buttonClass = cn('top-4 left-4', position, className);

  return (
    <Button
      color="neutral"
      className={buttonClass}
      disabled={pending}
      type="submit"
    >
      {pending ? (
        <Loading size="md" />
      ) : (
        <>
          <IoArrowBack size={24} />
          {children}
        </>
      )}
    </Button>
  );
};
