import { ResponsiveType } from '../utils';

export function getLoadingIconResponsive(size?: ResponsiveType) {
  switch (size) {
    case 'xs': {
      return 'w-12';
    }
    case 'sm': {
      return 'w-16';
    }
    case 'md': {
      return 'w-24';
    }
    case 'lg': {
      return 'w-32';
    }
    default: {
      return 'w-20';
    }
  }
}
