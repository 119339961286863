'use client';
import { Button } from '@prickle/next-ui';
import { cn } from '@prickle/ui';
import { useSearchParams, useRouter, usePathname } from 'next/navigation';

export const PaginationItem = ({
  page,
  isActive,
}: {
  page: number;
  isActive?: boolean;
}) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();

  const handleClick = () => {
    const params = new URLSearchParams(searchParams.toString());
    params.set('page', String(page - 1));

    router.push(pathname + '?' + params.toString());
  };

  return (
    <Button
      className={cn(
        'inline-flex items-center justify-center w-10 h-10 text-sm',
        'font-medium transition-colors rounded-md whitespace-nowrap',
        'ring-offset-background focus-visible:outline-none focus-visible:ring-2',
        'focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none',
        'disabled:opacity-50 hover:bg-neutral hover:text-neutral-content',
        {
          'bg-neutral': isActive,
          'text-neutral-content': isActive,
          'hover:bg-neutral': !isActive,
          'hover:text-neutral-content': !isActive,
        },
      )}
      ghost
      aria-current="page"
      onClick={handleClick}
    >
      {page}
    </Button>
  );
};
