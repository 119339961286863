'use client';
import { Button } from '@prickle/next-ui';
import { cn } from '@prickle/ui';
import { useTranslations } from 'next-intl';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';

export const PaginationNextButton = ({
  page,
  totalPages,
}: {
  page: number;
  totalPages: number;
}) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const t = useTranslations('Pagination.Next');

  const handleClick = () => {
    if (page === totalPages) {
      return;
    }
    const params = new URLSearchParams(searchParams.toString());
    params.set('page', String(page));

    router.push(pathname + '?' + params.toString());
  };

  return (
    <Button
      className={cn(
        'disabled inline-flex items-center justify-center whitespace-nowrap',
        'rounded-md text-sm font-medium ring-offset-background transition-colors',
        'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring',
        'focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
        'hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2 gap-1 pr-2.5',
      )}
      aria-label={t('aria-label')}
      onClick={handleClick}
      ghost
      disabled={page === totalPages}
    >
      <span>{t('button')}</span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="w-4 h-4 lucide lucide-chevron-right"
      >
        <path d="m9 18 6-6-6-6"></path>
      </svg>
    </Button>
  );
};
