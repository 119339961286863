'use client';
import { Button, ColorType, Loading } from '@prickle/next-ui';
import { cn } from '@prickle/ui';
import { useFormStatus } from 'react-dom';

export const SubmitFormButton = ({
  color = 'neutral',
  children,
  className,
  isValid = true,
  outline = false,
  form,
  isSubmitting = false,
}: {
  children: React.ReactNode;
  className?: string;
  color?: ColorType;
  isValid?: boolean;
  outline?: boolean;
  form?: string;
  isSubmitting?: boolean;
}) => {
  const { pending } = useFormStatus();
  const buttonClass = cn(className);

  return (
    <Button
      color={color}
      className={buttonClass}
      disabled={pending || isSubmitting || !isValid}
      type="submit"
      outline={outline}
      form={form}
    >
      {pending || isSubmitting ? <Loading size="md" /> : children}
    </Button>
  );
};
