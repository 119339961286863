'use client';
import { ReactNode } from 'react';
import { ServerMessage } from './server-message.component';
import { IServerActionState } from '@prickle/dto';
import { useFormState } from 'react-dom';
import { cn } from '@prickle/ui';

export const SubmitForm = ({
  action,
  children,
  className,
}: {
  action: (
    state: IServerActionState,
    payload: FormData,
  ) => IServerActionState | Promise<IServerActionState>;
  children: ReactNode | ReactNode[];
  className?: string;
}) => {
  const [state, dispatch] = useFormState<IServerActionState, FormData>(action, {
    isOk: false,
    message: '',
    issues: [],
  });

  return (
    <form action={dispatch} className={cn('space-y-4', className)}>
      {children}
      <ServerMessage {...state} />
    </form>
  );
};
